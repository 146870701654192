@charset "UTF-8";
img {
  width: 100%; }

/* background image */
.bg-projetos {
  background-image: url("../img/tops/projetos.jpg");
  background-repeat: no-repeat; }

.bg-servicos {
  background-image: url("../img/tops/servicos.jpg");
  background-repeat: no-repeat; }

.bg-sobre {
  background-image: url("../img/tops/sobre.jpg");
  background-repeat: no-repeat; }

.bg-contato {
  background-image: url("../img/tops/contato.jpg");
  background-repeat: no-repeat; }

.banner-01 {
  background-image: url("../img/banner/01.jpg"); }

.banner-02 {
  background-image: url("../img/banner/02.jpg"); }

.banner-03 {
  background-image: url("../img/banner/03.jpg"); }

/* end - background image */
.logo-img {
  width: 100%; }

.title-servicos {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 700; }

.title-servicos:hover {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 15px; }

.logo-space {
  padding-top: 5px;
  padding-bottom: 10px; }

.logo-pad {
  padding-top: 15px; }

.img-ser {
  width: 100%;
  height: 400px; }

/* pré header */
.hd-sec {
  background: #23285f none repeat scroll 0 0;
  padding: 10px 0; }

/* end - pré header */
/* menu */
.main-menu ul li.active a {
  color: #f9c122;
  position: relative; }

.main-menu ul li a:hover {
  -moz-transition: all 0.4s 0s ease;
       transition: all 0.4s 0s ease;
  color: #f9c122; }

.main-menu ul li a {
  color: #23285f; }

/* end - menu */
/* scroll top */
#scrollUp {
  visibility: hidden; }

/* end - scroll top */
.sec-title h1 {
  color: #23285f; }

.about-us-tab .active a {
  background: #f9c122;
  color: #fff; }

.no-margin {
  margin: 0px !important; }

.intro-text i {
  color: #f9c122;
  float: left;
  margin-top: 6px; }

.service i, .why-choose i, .service i {
  background: #f9c122 none repeat scroll 0 0; }

.service-item .inner {
  cursor: pointer; }

.service-item .inner:hover {
  background: #23285f; }

.service-item .inner:hover i {
  color: #23285f; }

.call-to-action-text a.btn {
  background: #f9c122 none repeat scroll 0 0; }

.call-to-action-text a.btn:hover {
  background: #23285f;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.call-to-action-overlay {
  background-color: rgba(70, 70, 70, 0.3); }

/* footer */
.footer-bottom-sec {
  background: #23285f none repeat scroll 0 0;
  padding: 10px 0px; }

.footer-bottom-sec-new {
  background: #fff none repeat scroll 0 0;
  padding: 20px 0px; }

/* end - footer */
.all-slide .owl-controls .owl-prev i, .all-slide .owl-controls .owl-next i {
  background: #f9c122 none repeat scroll 0 0;
  color: #fff; }

.all-slide .owl-controls .owl-next i:hover {
  background: #23285f; }

.icon-avalon {
  float: left;
  width: 21px; }

.images-overlay {
  background-color: rgba(249, 193, 34, 0.4); }

.project-sec .item img {
  width: 100%;
  height: 250px; }

.project-hoverlay {
  background: #f9c122 none repeat scroll 0 0; }

.project-sec .item:hover .project-text h3 {
  background: #23285f none repeat scroll 0 0; }

.prject-thumb a {
  background-color: rgba(35, 40, 95, 0.4); }

.prject-thumb a i {
  color: #f9c122; }

.single-input-fieldsbtn input[type="submit"] {
  background: #f9c122 none repeat scroll 0 0;
  border-color: #f9c122; }

.single-input-fieldsbtn input[type="submit"]:hover {
  background: #23285f;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  border-color: #23285f; }

.title {
  margin: 0 0 20px;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
  color: #464646;
  text-transform: uppercase;
  font-size: 20px; }

.pad {
  padding-top: 60px; }

.pad-cont {
  padding: 60px 0px 0px 0px; }

.pad-space {
  padding-left: 20px; }

.page-heading li:last-child a {
  color: #23285f; }

/* menu mobile */
.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #23285f;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-weight: 700;
  /*border: 1px solid #23285f;*/
  border-radius: 10px;
  margin-top: 4px;
  margin-right: 10px;
  margin-bottom: 10px; }

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #23285f;
  height: 3px;
  margin-top: 3px; }

.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999; }

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #383838;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-transform: uppercase;
  background-color: #23285f; }

.mean-container .mean-nav ul li a:hover {
  background-color: #f9c122;
  color: #464646; }

/* end menu mobile */
.img-intelbras {
  width: 300px;
  padding-top: 35px; }

.footer-bottom-sec p {
  color: #fff;
  font-weight: 100;
  margin: 0;
  text-align: center; }

.float-d {
  float: right; }

.color-white {
  color: #fff; }

.img-avalon {
  width: 21px; }

.space-l {
  padding-left: 10px; }

.margin-five {
  margin: 5px; }

.asterisk {
  color: #f9c122;
  font-size: 14px; }

.top-mar {
  padding-top: 10px; }

.pad-in {
  padding-top: 7px; }

.img-sky {
  width: 130px; }

.space-tb {
  padding-top: 100px;
  padding-bottom: 100px; }

@media (min-width: 1200px) {
  /* background image */
  .bg-projetos {
    background-size: 100%; }
  .bg-servicos {
    background-size: 100%; }
  .bg-sobre {
    background-size: 100%; }
  .bg-contato {
    background-size: 100%; }
  /* end - background image */ }

@media (min-width: 768px) and (max-width: 991px) {
  .logo-pad {
    padding-top: 0px; }
  .main-menu ul li a {
    padding: 26px 14px; }
  .pad-in {
    padding-top: 2px; } }

@media (max-width: 767px) {
  /* logo */
  .img-footer {
    width: 300px;
    height: 100px; }
  /* end -logo */
  .pad-space {
    padding-left: 0px;
    padding-top: 60px; }
  /* dimensão do banner (home) */
  .all-slide .owl-item {
    height: 350px !important; }
  /* end - dimensão do banner (home) */
  /* background image */
  .banner-01 {
    background-image: url("../img/banner/01-mobile.jpg"); }
  .banner-02 {
    background-image: url("../img/banner/02-mobile.jpg"); }
  .banner-03 {
    background-image: url("../img/banner/03-mobile.jpg"); }
  /* end background image */
  /* botão */
  .about-us-tab a {
    padding: 10px 24px; }
  /* end - botão */
  .img-intelbras {
    width: 150px;
    padding-top: 35px; }
  .logo-img {
    width: 300px;
    margin: 0 auto;
    display: table; }
  .center {
    text-align: center;
    margin: 0 auto;
    display: table;
    padding-top: 10px;
    padding-bottom: 10px; }
  .pad-hdl {
    padding-top: 10px; }
  .img-sky {
    width: 100px; } }
